import { useEffect } from 'react';
import './styles/app.scss';
import Home from './components/pages/HomePage/Home';
// import Contact from './components/pages/Contact/Contact';
import { RoutingPaths } from './helpers/RoutingPaths';
import { Route, Routes } from 'react-router';
import Articles from './components/pages/ArticlesPage/Articles';
import Article from './components/pages/ArticlePage/Article';
import LegalInformations from './components/pages/LegalInformations';
import BrokersPage from './components/pages/BrokersPage';
import ReactGA from 'react-ga';

const TRACKING_ID = 'G-0MGP8464KR';
ReactGA.initialize(TRACKING_ID);

const App = () => {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <Routes>
      <Route path={RoutingPaths.HOME} element={<Home />} />
      <Route path={RoutingPaths.ARTICLES} element={<Articles />} />
      <Route path={RoutingPaths.ARTICLE} element={<Article />} />
      <Route path={RoutingPaths.LEGAL} element={<LegalInformations />} />
      <Route path={RoutingPaths.BROKERS} element={<BrokersPage />} />
    </Routes>
  );
};

export default App;
