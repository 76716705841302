import { useEffect } from 'react';
import './tweets.scss';
import Carousel from 'react-multi-carousel';
import CustomCarouselButtonGroup from './CustomCarouselButtonGroup';
import 'react-multi-carousel/lib/styles.css';
import SanityApi from '../../sanity/SanityApi';
import { useDispatch, useSelector } from 'react-redux';
import { setTweets } from '../../store/tweets/tweets.reducer';
// import imageUrlBuilder from '@sanity/image-url';
// import { client } from '../../sanityClient';
import { PortableText } from '@portabletext/react';

const QUERY = encodeURIComponent(
  '*[_type == "tweet"]{_id, _createdAt, type,  _updatedAt, author, authorNickname, "profileImage": profileImage.asset->url, date, link, content}'
);
const URL = SanityApi.url + QUERY;

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1440 },
    items: 4,
  },
  smallDesktop: {
    breakpoint: { max: 1440, min: 768 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 768, min: 320 },
    items: 1,
  },
};

const Tweets = () => {
  const dispatch = useDispatch();
  // const reduxTweetsState = useSelector(tweetsState);
  const reduxTweetsState = useSelector(
    (state: any) => state.tweetsReducer.tweets
  );
  // const builder = imageUrlBuilder(client);

  // const urlFor = (source) => {
  //   return builder.image(source);
  // };

  useEffect(() => {
    fetch(URL)
      .then((res) => res.json())
      .then(({ result }) => {
        dispatch(setTweets(result));
      })
      .catch((err) => console.error(err));
  }, [dispatch]);
  return (
    <>
      {reduxTweetsState && (
        <div className="tweets">
          <div className="tweets__content">
            <h2>Najciekawsze tweety tego tygodnia</h2>
            {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p> */}
            <iframe
              title="twitter"
              // width="500"
              // height="400"
              data-tweet-url="https://twitter.com/financialjuice"
              src="data:text/html;charset=utf-8,%3Ca%20class%3D%22twitter-timeline%22%20href%3D%22https%3A//twitter.com/financialjuice%3Fref_src%3Dtwsrc%255Etfw%22%3ETweets%20by%20elonmusk%3C/a%3E%0A%3Cscript%20async%20src%3D%22https%3A//platform.twitter.com/widgets.js%22%20charset%3D%22utf-8%22%3E%3C/script%3E%0A"
            ></iframe>
            {/* <Carousel
              responsive={responsive}
              draggable={false}
              showDots={false}
              renderButtonGroupOutside={true}
              customButtonGroup={<CustomCarouselButtonGroup />}
              arrows={false}
              className="tweets__content-tiles"
            >
              {reduxTweetsState.map((e, index) => {
                console.log(e);
                const date = new Date(e.date);
                return (
                  <div className="tweets__content-tiles-tile" key={index}>
                    <div className="tweets__content-tiles-tile-header">
                      <div className="tweets__content-tiles-tile-header-right">
                        <div className="tweets__content-tiles-tile-header-right-picture">
                          <img
                            src="/elon.jpg"
                            width={40}
                            height={40}
                            alt="profile"
                          ></img>
                        </div>
                        <div className="tweets__content-tiles-tile-header-right-author">
                          <p>
                            <strong>{e.author}</strong>
                          </p>
                          <p>@{e.authorNickname}</p>
                        </div>
                      </div>
                      <div className="tweets__content-tiles-tile-header-left">
                        <a href={e.link} target="_blank" rel="noreferrer">
                          <img
                            src="/tweets/tw.svg"
                            width={20}
                            height={20}
                            alt="twitter"
                          ></img>
                        </a>
                      </div>
                    </div>
                    <div className="tweets__content-tiles-tile-content">
                      <PortableText value={e.content} />
                    </div>
                    <div className="tweets__content-tiles-tile-footer">
                      <div className="tweets__content-tiles-tile-footer-right">
                        <p>
                          {date.getHours()}:{date.getMinutes()} ·{' '}
                          {date.getDate()}.{date.getMonth() < 10 ? '0' : ''}
                          {date.getMonth()}.{date.getFullYear()}
                        </p>
                        <p></p>
                      </div>
                      <div className="tweets__content-tiles-tile-footer-left">
                        i
                      </div>
                    </div>
                  </div>
                );
              })}
            </Carousel> */}
            {/* <div className="tweets__content-tiles">
            {tempData.map((e, index) => {
              return (
                <div className="tweets__content-tiles-tile" key={index}>
                  <div className="tweets__content-tiles-tile-header">
                    <div className="tweets__content-tiles-tile-header-right">
                      <div className="tweets__content-tiles-tile-header-right-picture"></div>
                      <div className="tweets__content-tiles-tile-header-right-author">
                        <p>
                          <strong>{e.author}</strong>
                        </p>
                        <p>{e.authorNickname}</p>
                      </div>
                    </div>
                    <div className="tweets__content-tiles-tile-header-left">
                      <img
                        src="/tweets/tw.svg"
                        width={20}
                        height={20}
                        alt="twitter"
                      ></img>
                    </div>
                  </div>
                  <div className="tweets__content-tiles-tile-content">
                    <p>{e.content}</p>
                  </div>
                  <div className="tweets__content-tiles-tile-footer">
                    <div className="tweets__content-tiles-tile-footer-right">
                      <p>{e.hour}·</p>
                      <p>{e.date}</p>
                    </div>
                    <div className="tweets__content-tiles-tile-footer-left">
                      i
                    </div>
                  </div>
                </div>
              );
            })}
          </div> */}
          </div>
        </div>
      )}
    </>
  );
};

export default Tweets;
