// import { RoutingPaths } from '../../helpers/RoutingPaths';
import { useWindowSize } from '../../hooks/useWindowSize';
import { useState, useEffect } from 'react';
import './navbar.scss';

// const LINKS = ['Home', 'Articles', 'About', 'Contact'];
interface props {
  colorBg: boolean;
}

const LINKS = [
  {
    name: 'Strona główna',
    link: '/',
  },
  {
    name: 'Artykuły',
    link: '/articles/1',
  },
  {
    name: 'Informacje prawne',
    link: '/legal-informations',
  },
  {
    name: 'Lista brokerów',
    link: '/brokers',
  },
  // {
  //   name: 'O nas',
  //   link: '/about',
  // },
];

const Navbar = ({ colorBg }: props) => {
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const [selectedLink, setSelectedLink] = useState(0);
  const windowSize = useWindowSize();

  // const showMobileMenuCondition = () => {
  //   if(windowSize.width <= 768)
  // }

  useEffect(() => {
    const currentLink = LINKS.find((link) =>
      link.link.includes(window.location.pathname)
    );
    if (currentLink) setSelectedLink(LINKS.indexOf(currentLink));
  }, []);

  return (
    <>
      <div
        className="navbar"
        style={{ backgroundColor: colorBg === true ? '#1536a9' : '' }}
      >
        <div className="navbar__content">
          <div
            className="navbar__content-mobile-menu"
            onClick={() => setShowMobileMenu(!showMobileMenu)}
          >
            <div></div>
            <div></div>
            <div></div>
          </div>
          <div className="navbar__content-left">
            <a href="/">
              Brokers <strong>Info</strong>
              {/* <img
                src="/brokersinfo-logo.png"
                width={1600}
                height={1600}
                alt="logo"
              /> */}
            </a>
          </div>
          <div
            className="navbar__content-right"
            style={{
              transform:
                windowSize.width <= 768 && !showMobileMenu
                  ? 'translateX(100%)'
                  : 'translateY(0)',
            }}
          >
            <div
              className="navbar__content-mobile-menu"
              onClick={() => setShowMobileMenu(!showMobileMenu)}
            >
              <div></div>
              <div></div>
              <div></div>
            </div>
            <div className="navbar__content-right-links">
              {LINKS.map((e, index) => {
                return (
                  <a
                    className={selectedLink === index ? 'selected-link' : ''}
                    href={e.link}
                    key={index}
                  >
                    {e.name}
                  </a>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Navbar;
