import { IButton } from '../../interfaces/index';

const DefaultButton = ({ text, disabled = false, onClick }: IButton) => {
  return (
    <button
      className={`button-default${disabled ? '-dsiabled' : ''}`}
      onClick={onClick}
    >
      {text}
    </button>
  );
};

export default DefaultButton;
