export const calculateDaysSincePosted = (date: Date) => {
  const postDate = new Date(date);
  const today = new Date();
  const diff = today.getTime() - postDate.getTime();
  const daysDiff = Math.ceil(diff / (1000 * 3600 * 24));
  if (daysDiff === 1) return `Dzisiaj`;
  if (daysDiff >= 2 && daysDiff <= 6) return `${daysDiff} dni temu`;
  if (daysDiff >= 7 && daysDiff <= 13) return `Tydzień temu`;
  if (daysDiff >= 14 && daysDiff <= 31)
    return `${Math.floor(daysDiff / 7)} tygodne temu`;
  if (daysDiff > 31 && daysDiff <= 62) return `Miesiąc temu`;
  if (daysDiff > 62 && daysDiff <= 124)
    return `${Math.floor(daysDiff / 31)} miesiące temu`;
  if (daysDiff >= 125 && daysDiff <= 365)
    return `${Math.floor(daysDiff / 31)} miesięcy temu`;
  if (daysDiff >= 366 && daysDiff < 730) return `Rok temu`;
  if (daysDiff >= 731 && daysDiff < 1460)
    return `${Math.floor(daysDiff / 365)} lata temu`;
};
