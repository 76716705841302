import './footer.scss';

// const PAGES = ['Strona główna', 'Artykuły'];
const PAGES = [
  {
    name: 'Strona główna',
    link: '/',
  },
  {
    name: 'Artykuły',
    link: '/articles/1',
  },
  {
    name: 'Informacje prawne',
    link: '/legal-informations',
  },
];

const SOCIALS = [
  {
    src: '/footer/tw.svg',
    width: 40,
    height: 40,
    alt: 'twitter',
  },
  {
    src: '/footer/fb.svg',
    width: 40,
    height: 40,
    alt: 'facebook',
  },
  {
    src: '/footer/ld.svg',
    width: 40,
    height: 40,
    alt: 'linkedin',
  },
];

const Footer = () => {
  return (
    <>
      <div className="footer">
        <div className="footer__content">
          <div className="footer__content-pages">
            <p>Pages</p>
            {PAGES.map((e, index) => {
              return (
                <a
                  className="footer__content-pages-page"
                  href={e.link}
                  key={index}
                >
                  {e.name}
                </a>
              );
            })}
          </div>
          <div className="footer__content-address">
            <img
              src="/footer/location.svg"
              width={40}
              height={40}
              alt="location"
            ></img>
            <p>0000 Warszawa</p>
          </div>
          <div className="footer__content-phone">
            <img
              src="/footer/phone.svg"
              width={40}
              height={40}
              alt="phone"
            ></img>
            <p>+98 000 000 000</p>
          </div>
          <div className="footer__content-socials">
            {SOCIALS.map((e, index) => {
              return (
                <img
                  src={e.src}
                  width={e.width}
                  height={e.height}
                  alt={e.alt}
                  key={index}
                ></img>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
