import imageUrlBuilder from '@sanity/image-url';
import { client } from '../../../../../sanityClient';
import './articleTile.scss';
import { PortableText } from '@portabletext/react';
import { calculateDaysSincePosted } from '../../../../../helpers/CalculateDaysSincePosted';

const ArticleTile = ({ data }) => {
  const builder = imageUrlBuilder(client);

  const urlFor = (source) => {
    return builder.image(source);
  };

  return (
    <div className="articles__tile">
      <div className="articles__tile-image">
        <img
          src={urlFor(data.image).width(500).height(300).url()}
          alt="articleImage"
        ></img>
      </div>
      <div className="articles__tile-body">
        <div className="articles__tile-info">
          <p>{data.category}</p>
          <p>·</p>
          <p>{calculateDaysSincePosted(data._createdAt)}</p>
        </div>
        <p className="articles__tile-title">{data.title}</p>
        {/* <p className="articles__tile-content">{data.content}</p> */}
        <div className="articles__tile-content">
          <PortableText value={data.content} />
        </div>
        <div className="articles__tile-footer">
          <p>{data.time} min czytania</p>
          <a
            href={`/article/${data._id}`}
            className="articles__tile-footer-right"
          >
            <p>Read full</p>
            <div></div>
          </a>
        </div>
      </div>
    </div>
  );
};

export default ArticleTile;
