import { useMemo } from 'react';
import './articlesPagination.scss';

const ArticlesPagination = ({ articles, pageId, articlesPerPage }) => {
  const pages = useMemo(() => {
    const arr: number[] = [];
    for (let i = 0; i < Math.ceil(articles.length / articlesPerPage); i++) {
      arr.push(i + 1);
    }
    return arr;
  }, [articles, articlesPerPage]);

  return (
    <>
      <div className="articles__pagination">
        {pages.map((e, index) => {
          return (
            <a
              href={`/articles/${e.toString()}`}
              style={{
                border: pageId === e.toString() ? '2px solid #1536a9' : '',
              }}
              key={index}
            >
              {e}
            </a>
          );
        })}
      </div>
    </>
  );
};

export default ArticlesPagination;
