import data from './brokersData.json';
import './brokersRanking.scss';
import BrokerTile from './components/BrokerTile';
import RemainingBrokers from './components/RemainingBrokers';

const BrokersRanking = ({ brokersRef }) => {
  return (
    <>
      <div className="brokersRanking" ref={brokersRef}>
        <div className="brokersRanking__content">
          <h2>Ranking najlepszych brokerów</h2>
          <div className="brokersRanking__content-ranking">
            {Object.values(data)[0]
              .slice(0, 3)
              .map((data, index) => {
                return <BrokerTile {...{ data, index }} />;
              })}
          </div>
          {/* <RemainingBrokers /> */}
          <p className="brokersRanking__content-risk">
            "Ostrzeżenie o ryzyku ‌Kontrakty CFD są złożonymi instrumentami
            obciążonymi wysokim ryzykiem szybkiej utraty pieniędzy na skutek
            stosowania dźwigni finansowej. 71% rachunków inwestorów detalicznych
            traci pieniądze, obracając kontraktami CFD za pośrednictwem tego
            usługodawcy. Powinieneś rozważyć, czy rozumiesz jak działają
            kontrakty CFD, i czy możesz pozwolić sobie na podjęcie wysokiego
            ryzyka utraty swoich pieniędzy. ‌‌Przeszłe zyski nie gwarantują
            przyszłych zarobków."
          </p>
        </div>
      </div>
    </>
  );
};

export default BrokersRanking;
