import BrokerTile from '../HomePage/BrokersRangking/components/BrokerTile';
import data from './data.json';
import './brokers.scss';
import Navbar from '../../Navbar';

const BROKERS_DATA = Object.values(data)[0];

const BrokersPage = () => {
  return (
    <>
      <Navbar colorBg={true} />
      <div className="brokers">
        <div className="brokers__content">
          <div className="brokers__content-tiles">
            {BROKERS_DATA.map((data, index) => {
              return <BrokerTile {...{ data, index }} />;
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default BrokersPage;
