import { createSlice } from '@reduxjs/toolkit';
import { articlesAdapter } from './articles.adapter';

export const articlesSlice = createSlice({
  name: '[ARTICLES]',
  initialState: articlesAdapter.getInitialState({
    articles: [],
  }),
  reducers: {
    SET_ARTICLES: (state, { payload }) => {
      state.articles = payload;
    },
  },
});

export const { SET_ARTICLES: setArticles } = articlesSlice.actions;

export default articlesSlice.reducer;
