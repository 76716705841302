import Navbar from '../../Navbar';
import './legalInformations.scss';

const LegalInformations = () => {
  return (
    <>
      <Navbar colorBg={true} />
      <div className="legal-info">
        <p>
          BrokersInfo nie ponosi jakiejkolwiek odpowiedzialności za ryzyko
          wynikające z transakcji inwestycyjnych lub innych rozporządzeń
          aktywami przeprowadzanych przez klienta na podstawie otrzymanych
          informacji lub analizy rynku. Wszystkie informacje zawarte na stronie
          przekazywane są wyłącznie jako informacje, bez zobowiązań lub
          nakłanianie do podejmowania jakichkolwiek działań. Nie stanowią one
          doradztwa inwestycyjnego, dlatego zalecamy skontaktowanie się z
          osobistym doradcą finansowym przed dokonaniem określonych decyzji lub
          transakcji.
        </p>
        <p>
          Ze względu na wysokie ryzyko powinieneś przeprowadzać takie transakcje
          tylko wtedy, gdy rozumiesz charakter kontraktów (i stosunków
          umownych), które zawierasz i jeśli jesteś w stanie w pełni ocenić
          zakres swojego potencjalnego ryzyka. Handel kontraktami futures,
          opcjami, forex, kontraktami CFD, akcjami, kryptowalutami i podobnymi
          instrumentami finansowymi nie jest odpowiedni dla wielu osób.
          Powinieneś dokładnie rozważyć, czy handel jest dla Ciebie odpowiedni w
          oparciu o Twoje doświadczenie, cele, sytuację finansową i inne istotne
          okoliczności.
        </p>
        <p>
          Wyniki osiągnięte w przeszłości nie mają wpływu na przyszłe wyniki.
        </p>
        <p>
          Pragniemy zwrócić uwagę na kilka konkretnych ryzyk, które mogą wynikać
          z handlu instrumentami finansowymi.
        </p>
        <p>
          <strong>Ryzyka związane z handlem elektronicznym</strong>
          <br />
          Zanim zaczniesz przeprowadzać transakcje w systemie elektronicznym,
          powinieneś dokładnie zapoznać się z regulaminem i postanowieniami
          giełdy oferującej ten system lub notowanych instrumentów finansowych,
          którymi zamierzasz handlować, a także warunków swojego brokera. Handel
          online wiąże się z nieodłącznym ryzykiem ze względu na czasy
          odpowiedzi/reakcji systemu i czasy dostępu, które mogą się różnić w
          zależności od warunków rynkowych, wydajności systemu i innych
          czynników, i na które nie masz wpływu. Powinieneś być świadomy tych
          dodatkowych zagrożeń w handlu elektronicznym, zanim przeprowadzisz
          transakcje inwestycyjne.
        </p>
        <p>
          <strong>Ryzyka związane z rynkiem akcji</strong>
          <br />
          Wszelkie opinie, wiadomości, badania, analizy, ceny lub inne
          informacje lub oświadczenia oferowane przez BrokersInfo mają formę
          ogólnych uwag i komentarzy. Nie stanowią porady inwestycyjnej.
          BrokersInfo nie ponosi odpowiedzialności za straty lub szkody, w tym
          między innymi utracone korzyści, które mogą wynikać bezpośrednio lub
          pośrednio z wykorzystania lub polegania na wyżej wymienionych
          opiniach, wiadomościach, dochodzeniach, analizach, cenach lub innych
          informacjach oferowanych przez firmę. Wszystkie opisane tu formy
          inwestycji wiążą się z dużym ryzykiem finansowym. Dotychczasowe wyniki
          papierów wartościowych, branży, sektora, rynku, produktu finansowego,
          strategii handlowej lub indywidualnej transakcji nie gwarantują
          żadnych przyszłych wyników ani zwrotów. Jako inwestor sam ponosisz
          pełną odpowiedzialność za swoje indywidualne decyzje inwestycyjne.
          Takie decyzje powinny opierać się na ocenie Twojej sytuacji
          finansowej, celów inwestycyjnych, tolerancji na ryzyko i potrzeb w
          zakresie płynności, a w razie wątpliwości powinny zostać wcześniej
          omówione z osobistym doradcą finansowym.
        </p>
        <p>
          <strong>Ryzyka związane z handlem kontraktami Futures</strong>
          <br />
          Transakcje kontraktami futures wiążą się z wysokim ryzykiem. Wysokość
          początkowego depozytu zabezpieczającego jest niska w stosunku do
          wartości kontraktu futures, dzięki czemu transakcje są „lewarowane”
          lub „geared”. Stosunkowo niewielki ruch na rynku ma proporcjonalnie
          większy wpływ na środki, które zdeponowałeś lub musisz zapłacić: może
          to działać zarówno dla Ciebie, jak i przeciwko Tobie. Możesz
          doświadczyć całkowitej utraty środków z początkowego depozytu
          zabezpieczającego, jak również wszelkich dodatkowych środków
          zdeponowanych w systemie. Jeśli rynek rozwija się w sposób sprzeczny z
          Twoją pozycją lub jeśli marże wzrosną, możesz zostać poproszony o
          wpłacenie znacznych dodatkowych środków w krótkim czasie, aby utrzymać
          swoją pozycję. W tym przypadku może się również zdarzyć, że Twoje
          konto brokera będzie na minusie i będziesz musiał dokonywać płatności
          poza początkową inwestycją.
        </p>
        <p>
          <strong>Ryzyka związane z handlem na rynku Forex</strong>
          <br />
          Handel walutami (Forex) na marżach wiąże się z wysokim ryzykiem i nie
          jest odpowiedni dla wszystkich inwestorów. Wyniki osiągnięte w
          przeszłości nie wskazują na przyszłe wyniki. Również w tym przypadku
          wysoki stopień lewarowania może działać zarówno przeciwko tobie, jak i
          na Twoją korzyść. Zanim zdecydujesz się zainwestować w waluty,
          powinieneś dokładnie ocenić swoje cele inwestycyjne, doświadczenie,
          możliwości finansowe i chęć podejmowania ryzyka. Istnieje możliwość
          częściowej lub całkowitej utraty początkowej inwestycji, dlatego nie
          powinieneś inwestować żadnych środków, których nie możesz całkowicie
          stracić w najgorszym przypadku. Powinieneś również być świadomy
          wszystkich zagrożeń związanych z obrotem walutami i w razie
          wątpliwości skontaktować się z niezależnym doradcą finansowym.
          <br />
          <br />
          Dźwignia umożliwia traderom, przy użyciu stosunkowo niewielkiej kwoty
          pieniędzy, zajęcie pozycji, która jest wielokrotnie większa od
          początkowej inwestycji. Ten efekt dźwigni może działać zarówno na
          Twoją korzyść, jak i na Twoją szkodę. Rynek Forex otwiera możliwość
          wykorzystania tego efektu dźwigni w wysokim stopniu; jednocześnie
          jednak stwarza też ryzyko poniesienia wysokich strat. Należy zachować
          ostrożność podczas korzystania z dźwigni w handlu lub inwestowaniu.
          Twoje ryzyko nie ogranicza się do początkowej inwestycji, może szybko
          spaść do ujemnego zakresu w przypadku silnych ruchów, co oznacza, że
          ​​możesz być zobowiązany do zapłaty znacznie więcej niż początkowa
          stawka. W przypadku brokerów spoza UE
        </p>
        <p>
          <strong>Ryzyka związane z handlem Opcjami</strong>
          <br />
          Obrót opcjami wiąże się ze znacznym ryzykiem i nie jest odpowiednią
          formą inwestycji dla wszystkich inwestorów. Ryzyko w handlu opcjami,
          że stracisz całą inwestycję w stosunkowo krótkim czasie, jest
          stosunkowo wysokie. Możliwe, że strata i wynikające z niej
          zobowiązanie płatnicze będą wyższe niż środki zainwestowane na Twoim
          rachunku papierów wartościowych. Dlatego upewnij się, że sprawdziłeś z
          wyprzedzeniem warunki swojego brokera handlowego oraz warunki banku
          powierniczego. Zanim zdecydujesz się zainwestować na rynku opcji,
          powinieneś dokładnie rozważyć swoje cele inwestycyjne, doświadczenie,
          zasoby finansowe i gotowość do podejmowania ryzyka. Istnieje
          możliwość, że poniesiesz znaczną stratę, która może szybko przekroczyć
          początkową inwestycję.
          <br /> <br />
          Również w tym przypadku wyniki osiągnięte w przeszłości nie wskazują
          na przyszłe wyniki.
        </p>
        <p>
          Wobec powyższego nie można zatem dać żadnej gwarancji ani obietnicy
          powodzenia lub opłacalności poczynionych inwestycji. Korzystając z tej
          witryny, użytkownik wyraźnie przyjmuje do wiadomości i zgadza się, że
          BrokersInfo nie ponosi odpowiedzialności za jakiekolwiek szkody
          wynikające z dokonanych inwestycji. Żadna z informacji udostępnionych
          przez BrokersInfo nie stanowi zaproszenia do obrotu instrumentami
          finansowymi lub wszelkiego rodzaju papierami wartościowymi.
        </p>
        <p>
          Pragniemy podkreślić, że handel akcjami, walutami, kontraktami CFD
          (kontraktami na różnice kursowe), Forex, zakładami typu spread
          betting, kontraktami futures i kryptowalutami itp. („Handel”) wiąże
          się ze znacznym ryzykiem strat i nie jest odpowiedni dla wszystkich
          inwestorów; wydarzenia z przeszłości niekoniecznie wskazują na
          przyszłe wyniki. Należy pamiętać, że ryzyko straty w handlu może być
          znaczne. Dlatego powinieneś poznać szczegóły swojej sytuacji
          finansowej i, jeśli to konieczne, skonsultować się z profesjonalną
          pomocą, aby ocenić, czy Twoja sytuacja osobista i finansowa pozwala na
          handel i czy jesteś w stanie podjąć wysokie ryzyko straty. Jeśli
          broker, doradca handlowy lub Ty sam tworzysz zlecenia warunkowe, takie
          jak zlecenie „stop loss” lub „stop limit”, niekoniecznie ograniczają
          one Twoje straty do zamierzonych kwot; warunki rynkowe mogą
          uniemożliwić takie limity.
        </p>
        <p>
          Ponadto chcielibyśmy zwrócić uwagę na efekt dźwigni w handlu, który
          może działać zarówno na Twoją korzyść, jak i przeciwko Tobie.
          Wykorzystanie takich efektów dźwigni może z jednej strony prowadzić do
          dużych strat w handlu, az drugiej do wysokich zysków.
        </p>
        <p>
          Przekazywane tutaj informacje służą wyłącznie do celów informacyjnych;
          nie stanowi porady inwestycyjnej. Nie mogą one zastąpić
          profesjonalnego doradztwa inwestycyjnego.
        </p>
        <p>
          BrokersInfo nie ponosi odpowiedzialności za ewentualne straty lub inne
          szkody. W związku z tym nie można dochodzić roszczeń odszkodowawczych.
          Należy również pamiętać, że handel ma pewien potencjał uzależniający.
          Jeśli znajdziesz się w niebezpieczeństwie, poszukaj profesjonalnej
          pomocy.
        </p>
      </div>
    </>
  );
};

export default LegalInformations;
