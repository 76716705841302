import Navbar from '../../Navbar';
import { useParams } from 'react-router';
import { useSelector } from 'react-redux';
import './article.scss';
import { PortableText } from '@portabletext/react';

const Article = () => {
  const { id } = useParams();
  const reduxArticlesState = useSelector(
    (state: any) => state.articlesReducer.articles
  );
  const article = reduxArticlesState.filter((e) => {
    if (e._id === id) return true;
    return false;
  })[0];

  return (
    <>
      <Navbar colorBg={true} />
      <div className="article">
        <div className="article__content">
          <h2 className="article__content-title">{article.title}</h2>
          {/* <p className="article__content-content">{article.content}</p> */}
          <PortableText value={article.content} />
        </div>
      </div>
    </>
  );
};

export default Article;
