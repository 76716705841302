import { combineReducers, configureStore } from '@reduxjs/toolkit';
import articlesReducer from './articles/articles.reducer';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { createLogger } from 'redux-logger';
import tweetsReducer from './tweets/tweets.reducer';

const rootReducer = combineReducers({ articlesReducer, tweetsReducer });

const persistedReducer = persistReducer({ key: 'root', storage }, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => {
    const defaultMiddleware = getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false,
    });

    return [...defaultMiddleware, createLogger()];
  },
});

export const persistedStore = persistStore(store);

export default store;
