// import '../styles/app.scss';
import '../../../styles/app.scss';
import Header from './Header';
import Navbar from '../../Navbar';
import BrokersRanking from './BrokersRangking';
import Tweets from '../../Tweets';
import Calendar from './Calendar';
import Articles from './ArticlesSection';
import Footer from '../../Footer';
import { useRef } from 'react';

const App = () => {
  const brokersRef = useRef<HTMLDivElement>(null);
  return (
    <div className="app">
      <Navbar colorBg={false} />
      <Header {...{ brokersRef }} />
      <BrokersRanking {...{ brokersRef }} />
      <Tweets />
      <Calendar />
      <Articles />
      <Footer />
    </div>
  );
};

export default App;
