import ReactGA from 'react-ga';

interface Props {
  category: string;
  action: string;
  label: string;
}

export const useAnalyticsEventTracker = (
  category: string,
  action: string,
  label: string
) => {
  // const eventTracker = (evtAction = action, evtLabel = label) => {
  //     ReactGA.event({category, evtAction, evtLabel});
  // }
  // return eventTracker;
  return ReactGA.event({ category, action, label });
};
