import { useAnalyticsEventTracker } from '../../../../../helpers/AnalyticsEventTracker';
import { IBrokerTile } from '../../../../../interfaces';
import DefaultButton from '../../../../common/DefaultButton';
import { TiTick } from 'react-icons/ti';
import { IoCheckmarkSharp } from 'react-icons/io5';

interface Props {
  data: IBrokerTile;
  index: number;
  isVisible?: boolean;
}

const BrokerTile = ({ data, index, isVisible = true }: Props) => {
  const gaEventTracker: any = useAnalyticsEventTracker(
    'Broker registration',
    'brokerRegistrationClick',
    'BrokerRegistrationClick'
  );

  return (
    // <CSSTransition
    //   in={animationIndex}
    //   timeout={300}
    //   className="brokerTile"
    //   unmountOnExit
    // >
    <div
      className="brokersRanking__content-ranking-tile-wrapper"
      style={{ display: isVisible ? 'block' : 'none' }}
    >
      <div className="brokersRanking__content-ranking-tile" key={index}>
        {data.rank && (
          <p>
            <strong>{data.rank} miejsce</strong>
          </p>
        )}
        <img
          src={data.img}
          width={data.imgWidth}
          height={'auto'}
          alt="broker"
        ></img>
        <h4>
          <strong>{data.name}</strong>
        </h4>
        <div className="brokersRanking__content-ranking-tile-features">
          {data.features.map((e, featuresIndex) => {
            return (
              <div key={featuresIndex}>
                {/* <div className="brokersRanking__content-ranking-tile-features-tick"></div> */}
                <IoCheckmarkSharp
                  size={30}
                  className="brokersRanking__checkmark"
                />
                <p>{e}</p>
              </div>
            );
          })}
        </div>
        <DefaultButton text="Zarejestruj się" />
        <div className="brokersRanking__content-ranking-tile-backside">
          <p>{data.description}</p>
          <a href={data.url} target="_blank" rel="noreferrer">
            <DefaultButton text="Zarejestruj się" onClick={gaEventTracker} />
          </a>
        </div>
        {/* <div className="brokersRanking__content-ranking-tile-description">
          Zobacz opis
        </div> */}
      </div>
    </div>
    // </CSSTransition>
  );
};

export default BrokerTile;

// Platformy: Meta Trader 4/5, AvaTradeGO
// Ochrona przed ujemnym saldem
// Waluty rachunków: USD, EUR, GBP
// Szeroka oferta (akcje, forex, krypto, CFD na ETF)
// Bogata oferta edukacyjna	+ Polski support
// + Zezwolenia regulacyjne na 5 kontynentach
// + Ponad 1250 instrumentów
// + Trading społecznościowy
// + Regulowany przez KNF
// + Działa na rynku od 2007 r.

// Platformy: xStation, xStation Mobile
// Ochrona przed ujemnym saldem
// Waluty rachunków: PLN, EUR, USD
// Dźwignia max 1:30
// Polski support 24/5	+ Najlepszy Broker Forex
// (wg Invest Cuffs 2022)
// + Najlepszy Polski Broker Forex & CFD
// (wg Invest Cuffs 2021)
// + Regulowany przez KNF
// + Ponad 5500 instrumentów
// + Bez rekwotowań
// + Darmowy pakiet edukacyjny
// (+100h nagrań 11 ekspertów
