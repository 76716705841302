import './calendar.scss';
import data from './data.json';
import CalendarTileMobile from './CalendarTileMobile';

const ROWS = [
  'CURRENCY',
  'IMPACT',
  'NEWS',
  'CURRENT RESULT',
  'PREVIOUS RESULT',
  'FORECAST',
];

const Calendar = () => {
  return (
    <>
      <div className="calendar">
        <div className="calendar__content">
          <div className="calendar__content-upper">
            <p>Kalendarz makroekonomiczny</p>
          </div>
          <div className="calendar__content-table">
            <div>
              <iframe
                title="calendar"
                src="https://sslecal2.investing.com?ecoDayBackground=%231434a8&columns=exc_flags,exc_currency,exc_importance,exc_actual,exc_forecast,exc_previous&features=datepicker,timezone&countries=25,32,6,37,72,22,17,39,14,10,35,43,56,36,110,11,26,12,4,5&calType=week&timeZone=8&lang=1"
                width="650"
                height="467"
                frameBorder="0"
                // allowTransparency={true}
                marginWidth={0}
                marginHeight={0}
              ></iframe>
              <div
                className="poweredBy"
                style={{ fontFamily: 'Arial, Helvetica, sans-serif' }}
              >
                <span
                  style={{
                    fontSize: '1ppx',
                    color: '#333333',
                    textDecoration: 'none',
                  }}
                >
                  Real Time Economic Calendar provided by{' '}
                  <a
                    href="https://www.investing.com/"
                    rel="noreferrer"
                    target="_blank"
                    style={{
                      fontSize: '11px',
                      color: '#06529D',
                      fontWeight: 'bold',
                    }}
                    className="underline_link"
                  >
                    Investing.com
                  </a>
                  .
                </span>
              </div>
            </div>
          </div>

          {/* <div className="calendar__content-table">
            <div className="calendar__content-table-heading">
              {ROWS.map((e, index) => {
                return (
                  <div className={`calendar-col-${index + 1}`} key={index}>
                    <p>{e}</p>
                  </div>
                );
              })}
            </div>
            <div className="calendar__content-table-data">
              {Object.values(data)[0].map((e, index) => {
                return (
                  <div className="calendar__content-table-row" key={index}>
                    <div className="calendar-col-1">
                      <p>{e.currency}</p>
                    </div>
                    <div className="calendar-col-2">
                      {[1, 2, 3].map((number, index) => {
                        if (e.impact < number)
                          return (
                            <img
                              src="/calendar/grayBull.svg"
                              width={40}
                              height={40}
                              alt="bull"
                            ></img>
                          );
                        else
                          return (
                            <img
                              src="/calendar/redBull.svg"
                              width={40}
                              height={40}
                              alt="bull"
                            ></img>
                          );
                      })}
                    </div>
                    <div className="calendar-col-3">
                      <p>{e.news}</p>
                    </div>
                    <div className="calendar-col-4">
                      <p>{e.current}</p>
                    </div>
                    <div className="calendar-col-5">
                      <p>{e.previous}</p>
                    </div>
                    <div className="calendar-col-6">
                      <p>{e.forecast}</p>
                    </div>
                  </div>
                );
              })}
            </div>
          </div> */}
          <CalendarTileMobile />
        </div>
      </div>
    </>
  );
};

export default Calendar;
