import { useEffect } from 'react';
import './articlesSection.scss';
import { client } from '../../../../sanityClient';
import imageUrlBuilder from '@sanity/image-url';
import SanityApi from '../../../../sanity/SanityApi';
import { useSelector, useDispatch } from 'react-redux';
import { setArticles } from '../../../../store/articles/articles.reducer';
import { PortableText } from '@portabletext/react';
import { calculateDaysSincePosted } from '../../../../helpers/CalculateDaysSincePosted';

const QUERY = encodeURIComponent(
  '*[_type == "article"]{_id, _createdAt, type,  _updatedAt, title, "image": image.asset->url, content, category, time}'
);
const URL = SanityApi.url + QUERY;

const Articles = () => {
  const dispatch = useDispatch();
  const reduxArticlesState = useSelector(
    (state: any) => state.articlesReducer.articles
  );
  const builder = imageUrlBuilder(client);

  const urlFor = (source) => {
    return builder.image(source);
  };

  useEffect(() => {
    fetch(URL)
      .then((res) => res.json())
      .then(({ result }) => {
        dispatch(setArticles(result));
      })
      .catch((err) => console.error(err));
  }, [dispatch]);

  return (
    <>
      {reduxArticlesState && (
        <div className="articles">
          <div className="articles__content">
            <h2>Artykuły</h2>
            {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p> */}
            <div className="articles__content-tiles">
              {reduxArticlesState.slice(0, 3).map((e, index) => {
                const timeSincePosted = calculateDaysSincePosted(e._createdAt);
                return (
                  <div className="articles__content-tiles-tile" key={index}>
                    <div className="articles__content-tiles-tile-image">
                      <img
                        src={urlFor(e.image).width(500).height(300).url()}
                        alt="article_image"
                      ></img>
                    </div>
                    <div className="articles__content-tiles-tile-body">
                      <div className="articles__content-tiles-tile-info">
                        <p>{e.category}</p>
                        <p>·</p>
                        <p>{timeSincePosted}</p>
                      </div>
                      <p className="articles__content-tiles-tile-title">
                        {e.title}
                      </p>
                      <div className="articles__content-tiles-tile-content">
                        <PortableText value={e.content} />
                      </div>
                      <div className="articles__content-tiles-tile-footer">
                        <p>{e.time} min czytania</p>
                        <a
                          href={`/article/${e._id}`}
                          className="articles__content-tiles-tile-footer-right"
                        >
                          <p>Czytaj całość</p>
                          <div></div>
                        </a>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Articles;
