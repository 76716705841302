import './calendarTileMobile.scss';
import data from './data.json';

const HEADINGS = [
  'CURRENCY',
  'IMPACT',
  'NEWS',
  'CURRENT RESULT',
  'PREVIOUS RESULT',
  'FORECAST',
];

const CalendarTileMobile = () => {
  return (
    <div className="calendar__mobile-tiles">
      {Object.values(data)[0].map((e, index) => {
        return (
          <div className="calendar__mobile-tile" key={index}>
            <div className="calendar__mobile-tile-left">
              {HEADINGS.map((e, headingsIndex) => {
                return (
                  <div
                    className="calendar__mobile-tile-left-data"
                    key={headingsIndex}
                  >
                    <p>{e}</p>
                  </div>
                );
              })}
            </div>
            <div className="calendar__mobile-tile-right">
              {Object.values(e).map((e, rightIndex) => {
                if (index !== 1)
                  return (
                    <div
                      className="calendar__mobile-tile-right-data"
                      key={rightIndex}
                    >
                      <p>{e}</p>
                    </div>
                  );
                else
                  return (
                    <div className="calendar__mobile-tile-right-data">
                      {[1, 2, 3].map((number, bullIndex) => {
                        if (e < number)
                          return (
                            <img
                              src="/calendar/grayBull.svg"
                              width={40}
                              height={40}
                              alt="bull"
                              key={bullIndex}
                            ></img>
                          );
                        else
                          return (
                            <img
                              src="/calendar/redBull.svg"
                              width={40}
                              height={40}
                              alt="bull"
                              key={bullIndex}
                            ></img>
                          );
                      })}
                    </div>
                  );
                // return ([1, 2, 3].map((number, bullIndex) => {
                //   if (e < number)
                //     return (
                //       <img
                //         src="/calendar/grayBull.svg"
                //         width={40}
                //         height={40}
                //         alt="bull"
                //         key={bullIndex}
                //       ></img>
                //     );
                //   else
                //     return (
                //       <img
                //         src="/calendar/redBull.svg"
                //         width={40}
                //         height={40}
                //         alt="bull"
                //         key={bullIndex}
                //       ></img>
                //     );
                // }));
              })}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default CalendarTileMobile;
