import React from 'react';
import { useWindowSize } from '../../../../hooks/useWindowSize';
import DefaultButton from '../../../common/DefaultButton';
import './header.scss';

const Header = ({ brokersRef }) => {
  const windowSize = useWindowSize();
  const executeScroll = () => {
    const currentRef = brokersRef.current;
    if (currentRef)
      window.scrollTo({
        top: currentRef.offsetTop,
        behavior: 'smooth',
      });
  };
  return (
    <>
      <div className="header">
        <div className="header__background">
          <img
            src={
              windowSize.width > 600
                ? '/header/background.webp'
                : '/header/background-mobile.webp'
            }
            width={windowSize.width > 600 ? 1776 : 417}
            height={windowSize.width > 600 ? 927 : 844}
            alt="background"
          ></img>
          <img
            src={
              windowSize.width > 600
                ? '/header/background-cover.png'
                : '/header/background-cover-mobile.png'
            }
            width={windowSize.width > 600 ? 1776 : 417}
            height={windowSize.width > 600 ? 927 : 844}
            alt="background-cover"
          ></img>
        </div>
        <div className="header__content">
          <h1>Najlepsza porównywarka brokerów</h1>
          <h5>Poznaj najbardziej sprawdzonych brokerów na rynku</h5>
          <DefaultButton text="SPRAWDŹ" onClick={executeScroll} />
        </div>
      </div>
    </>
  );
};

export default Header;
