import { createSlice } from '@reduxjs/toolkit';
import { tweetsAdapter } from './tweets.adapter';

export const tweetsSlice = createSlice({
  name: '[TWEETS]',
  initialState: tweetsAdapter.getInitialState({
    tweets: [],
  }),
  reducers: {
    SET_TWEETS: (state, { payload }) => {
      state.tweets = payload;
    },
  },
});

export const { SET_TWEETS: setTweets } = tweetsSlice.actions;

export default tweetsSlice.reducer;
