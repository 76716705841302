import { useEffect } from 'react';
import SanityApi from '../../../sanity/SanityApi';
import Navbar from '../../Navbar';
import ArticleTile from './components/ArticlesTile/ArticleTile';
import './articles.scss';
import { useParams } from 'react-router';
import { useSelector } from 'react-redux';
import ArticlesPagination from './components/ArticlesPagination/ArticlesPagination';
import { setArticles } from '../../../store/articles/articles.reducer';
import { useDispatch } from 'react-redux';

const QUERY = encodeURIComponent(
  '*[_type == "article"]{_id, _createdAt, type,  _updatedAt, title, "image": image.asset->url, content, category, time}'
);
const URL = SanityApi.url + QUERY;
const ARTICLES_PER_PAGE = 6;

const Articles = () => {
  const { id }: any = useParams();
  const reduxArticlesState = useSelector(
    (state: any) => state.articlesReducer.articles
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (!reduxArticlesState.length)
      fetch(URL)
        .then((res) => res.json())
        .then(({ result }) => {
          dispatch(setArticles(result));
        })
        .catch((err) => console.error(err));
  }, [reduxArticlesState.length, dispatch]);

  return (
    <>
      <Navbar colorBg={true} />
      {reduxArticlesState && (
        <div className="articles">
          <div className="articles__tiles">
            {reduxArticlesState
              .slice(ARTICLES_PER_PAGE * (id - 1), ARTICLES_PER_PAGE * id)
              .map((e: any, index: number) => {
                return <ArticleTile data={e} key={index} />;
              })}
          </div>
          {reduxArticlesState >= 6 && (
            <ArticlesPagination
              articles={reduxArticlesState}
              pageId={id}
              articlesPerPage={ARTICLES_PER_PAGE}
            />
          )}
        </div>
      )}
    </>
  );
};

export default Articles;
